@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: local("Avenir LT Std 55 Roman"), url("./fonts/AvenirLTStd-Roman.woff") format("woff"); }

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 400;
  src: local("Avenir LT Std 55 Oblique"), url("./fonts/AvenirLTStd-Oblique.woff") format("woff"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: local("Avenir LT Std 85 Heavy"), url("./fonts/AvenirLTStd-Heavy.woff") format("woff"); }

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 700;
  src: local("Avenir LT Std 85 Heavy Oblique"), url("./fonts/AvenirLTStd-HeavyOblique.woff") format("woff"); }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src: local("Avenir LT Std 95 Black"), url("./fonts/AvenirLTStd-Black.woff") format("woff"); }

@font-face {
  font-family: "Avenir";
  font-style: italic;
  font-weight: 900;
  src: local("Avenir LT Std 95 Black Oblique"), url("./fonts/AvenirLTStd-BlackOblique.woff") format("woff"); }

@font-face {
  font-family: "paris2024semibold";
  src: url("./fonts/paris2024-semibold-webfont.eot");
  src: url("./fonts/paris2024-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/paris2024-semibold-webfont.woff2") format("woff2"), url("./fonts/paris2024-semibold-webfont.woff") format("woff"), url("./fonts/paris2024-semibold-webfont.ttf") format("truetype"), url("./fonts/paris2024-semibold-webfont.svg#paris2024semibold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "paris2024black";
  src: url("./fonts/paris2024-black-webfont.eot");
  src: url("./fonts/paris2024-black-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/paris2024-black-webfont.woff2") format("woff2"), url("./fonts/paris2024-black-webfont.woff") format("woff"), url("./fonts/paris2024-black-webfont.ttf") format("truetype"), url("./fonts/paris2024-black-webfont.svg#paris2024black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "paris2024bold";
  src: url("./fonts/paris2024-bold-webfont.eot");
  src: url("./fonts/paris2024-bold-webfont.eot?#iefix") format("embedded-opentype"), url("./fonts/paris2024-bold-webfont.woff2") format("woff2"), url("./fonts/paris2024-bold-webfont.woff") format("woff"), url("./fonts/paris2024-bold-webfont.ttf") format("truetype"), url("./fonts/paris2024-bold-webfont.svg#paris2024bold") format("svg");
  font-weight: normal;
  font-style: normal; }

html {
  background-color: #292929; }

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro";
  font-size: 13px;
  background-color: white;
  color: #292929;
  -webkit-overflow-scrolling: touch; }

p {
  font-family: "Source Sans Pro"; }

a {
  font-family: "Source Sans Pro";
  text-decoration: none; }

h1 {
  font-family: "Source Sans Pro";
  font-size: 30px;
  font-weight: 900;
  margin: 0; }

h4 {
  font-family: "Source Sans Pro";
  font-weight: 500;
  margin: 0;
  font-size: 14px; }

button {
  padding: 10px 30px;
  min-width: 140px;
  background: transparent;
  text-transform: uppercase;
  font-size: 13px;
  transition: all 0.4s;
  margin-top: 5%;
  border: 1px solid #292929;
  float: right;
  letter-spacing: 1px; }
  button:active, button:focus, button:focus-within, button:visited {
    cursor: pointer;
    background: #292929;
    color: white;
    transition: all 0.4s; }
  button:disabled {
    border: 1px solid #c1c1c1;
    color: #c1c1c1; }
  button:hover {
    cursor: pointer;
    background: #292929;
    color: white;
    transition: all 0.4s; }
    button:hover:disabled {
      border: 1px solid #c1c1c1;
      background-color: transparent;
      color: #c1c1c1;
      cursor: no-drop; }

.pdf__link {
  text-decoration: none;
  color: inherit;
  font-size: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 40px; }
  .pdf__link img {
    margin-right: 10px; }
  .pdf__link p {
    border-bottom: 1px solid #292929;
    line-height: 30px; }

.twitter-timeline {
  max-width: 100%;
  height: 60vh !important;
  display: flex; }

.fa {
  font-size: 25px;
  color: #ffffff; }

.panel-title {
  font-size: 25px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif; }

.panel-heading {
  background-color: #1da1f2 !important;
  color: #1da1f2 !important; }

.panel {
  margin-top: 25px; }

h2.timeline-Header-subtitle {
  display: none !important; }

.form__container {
  width: 90%;
  background: whitesmoke;
  overflow: auto;
  padding-bottom: 5%; }
  @media (max-width: 768px) {
    .form__container {
      width: 100%; } }
  .form__container form {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 1px; }
    .form__container form h1 {
      font-family: "paris2024semibold";
      text-transform: uppercase;
      max-width: 520px; }
      @media (max-width: 768px) {
        .form__container form h1 {
          max-width: 100%; } }
    .form__container form h3 {
      font-size: 18px; }
    @media (max-width: 768px) {
      .form__container form {
        width: 92%; } }
    .form__container form small {
      display: flex;
      justify-content: flex-end;
      color: #292929;
      position: relative;
      bottom: 15px; }
      @media (max-width: 768px) {
        .form__container form small {
          bottom: 5px; } }
  .form__container .form {
    display: flex;
    flex-direction: column; }
    .form__container .form__titles {
      padding: 10% 0 5% 0; }
      .form__container .form__titles p {
        font-family: "Avenir";
        color: white;
        font-size: 14px;
        background-color: #292929;
        padding: 10px 15px;
        display: inline-block; }
      .form__container .form__titles a {
        font-weight: bold;
        color: inherit; }
      .form__container .form__titles span {
        font-family: "Source Sans Pro";
        color: gray;
        font-size: 15px;
        font-weight: 600; }
  .form__container .btn__pdf__container {
    display: flex;
    margin: 0 auto;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px; }
    .form__container .btn__pdf__container button {
      padding: 5px 20px;
      font-size: 12px; }
      @media (max-width: 768px) {
        .form__container .btn__pdf__container button {
          padding: 5px 10px; } }
  .form__container .react-pdf__Page canvas {
    width: 100% !important;
    margin: 0 auto;
    height: auto !important; }
  .form__container .option__title {
    margin: 10% 0 0 0;
    font-size: 18px; }
  .form__container .help_message {
    font-family: "Avenir";
    color: white;
    font-size: 14px;
    background-color: #292929;
    padding: 10px 15px;
    display: inline-block; }

.btn__container {
  display: flex;
  justify-content: space-between; }

label {
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: #383838; }

.message__error {
  color: red;
  font-family: "Source Sans Pro";
  font-size: 12px;
  position: absolute;
  top: 92px;
  right: 3px; }

.checkbox__message__error {
  color: red;
  font-family: "Source Sans Pro";
  font-size: 12px;
  top: 0px;
  position: relative;
  right: 3px; }

.react-select-container {
  position: relative; }
  .react-select-container .message__error {
    top: 60px;
    right: 2px; }

.input__container {
  position: relative; }
  .input__container .input {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }

input,
textarea {
  padding: 20px;
  font-family: "Source Sans Pro";
  margin: 5px 0 30px 0;
  border: 1px solid #efeded;
  width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  color: #2d2d2d;
  font-size: 17px;
  box-sizing: border-box; }
  input::placeholder,
  textarea::placeholder {
    font-family: "Source Sans Pro";
    color: #cccccc;
    font-size: 15px; }
  input:focus,
  textarea:focus {
    outline-width: 1px;
    border: 1px solid #2684ff !important; }

.textarea__container {
  margin-top: 30px; }
  .textarea__container textarea {
    min-height: 70px;
    min-width: 100%; }

.tooltip p {
  font-size: 14px;
  font-family: "Source Sans Pro"; }

select {
  padding: 20px 5px;
  background-color: white;
  margin: 5px 0 30px 0;
  border: 1px solid #efeded;
  height: 60px;
  width: 100%; }

option {
  font-family: "Source Sans Pro";
  padding-left: 15px;
  color: #cccccc;
  font-size: 12px; }

.checkbox {
  /* Hide the browser's default checkbox */
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
  /* Create a custom checkbox */ }
  .checkbox .label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end; }
  .checkbox .container_checkbox {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 27px; }
  .checkbox .container_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white; }
  .checkbox .container_checkbox:hover input ~ .checkmark {
    background-color: white; }
  .checkbox .container_checkbox input:checked ~ .checkmark {
    background-color: #292929; }
  .checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox .container_checkbox input:checked ~ .checkmark:after {
    display: block; }
  .checkbox .container_checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white; }

.__react_component_tooltip.show.place-top.type-light.tooltip {
  top: 10px !important;
  left: 0px !important;
  width: 100% !important;
  z-index: 100000;
  right: 0;
  background-color: #292929;
  color: white;
  border-radius: 0px;
  line-height: 10px;
  padding: 10px;
  text-align: center; }
  @media (max-width: 768px) {
    .__react_component_tooltip.show.place-top.type-light.tooltip {
      display: none; } }

.__react_component_tooltip.type-light.place-top:after {
  border-top-color: none;
  border-top-style: none;
  border-top-width: none; }

.tooltip p {
  font-size: 14px;
  font-family: "Avenir";
  padding-left: 10px;
  line-height: 20px;
  margin: 0; }

.submit__btn {
  width: 100%; }

.layout__container {
  display: flex;
  flex-direction: raw;
  height: 100vh; }
  .layout__container .logo {
    position: relative;
    font-family: "Source Sans Pro";
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    bottom: 12%;
    letter-spacing: 1px;
    color: #292929;
    transition: letter-spacing 0.3s; }
    .layout__container .logo:hover {
      letter-spacing: 2px;
      transition: letter-spacing 0.3s; }
    @media (max-width: 768px) {
      .layout__container .logo {
        display: none; } }

.sticky {
  background: whitesmoke;
  width: 8%;
  min-width: 8%;
  max-width: 8%; }
  .sticky p {
    top: 135px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    transform: rotate(270deg);
    text-align: right;
    z-index: 100000;
    width: auto;
    position: relative; }
    @media (max-width: 768px) {
      .sticky p {
        top: 170px; } }

.timeline__container {
  width: 22%;
  min-width: 22%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%; }
  .timeline__container .logo a {
    text-align: center; }
  .timeline__container .logo img {
    max-width: 320px;
    margin-top: 50px;
    width: 55%;
    right: 20%;
    position: relative; }
  @media (max-width: 768px) {
    .timeline__container {
      display: none; } }
  .timeline__container a {
    text-decoration: none;
    color: inherit; }
    .timeline__container a:last-child .line {
      display: none; }
  .timeline__container button {
    font-family: "Source Sans Pro"; }
  .timeline__container h3 {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Source Sans Pro";
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: 0;
    align-items: stretch; }
    .timeline__container h3.inactive {
      color: #b9b9b9;
      cursor: no-drop; }
  .timeline__container .dot {
    height: 10px;
    min-height: 10px;
    width: 10px;
    min-width: 10px;
    border: #b9b9b9 1px solid;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px; }
    .timeline__container .dot.active {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      position: relative;
      border: 1px solid black;
      border-radius: 50%;
      border-right-color: transparent;
      animation: cssload-spin 1500ms infinite linear; }
    .timeline__container .dot.finished {
      border: #292929 1px solid;
      background-color: #292929; }
    .timeline__container .dot.active.finished {
      border: 1px solid #292929;
      background-color: transparent;
      border-radius: 50%;
      border-right-color: transparent;
      animation: cssload-spin 1500ms infinite linear; }

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes cssload-spin {
  .timeline__container 100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .timeline__container .line {
    height: 30px;
    width: 1px;
    background-color: #c1c1c1;
    display: inline-block;
    margin: 5px 0 5px 5px;
    pointer-events: none; }

.step__container {
  width: 100%;
  background: whitesmoke;
  overflow: auto;
  padding: 10% 0; }
  @media (max-width: 768px) {
    .step__container {
      width: 100%;
      margin: 0 auto; } }
  .step__container .step {
    width: 80%; }
    @media (max-width: 768px) {
      .step__container .step {
        width: 90%; } }
    .step__container .step h1 {
      font-family: "paris2024semibold";
      text-transform: uppercase; }
    .step__container .step h2 {
      font-family: "Source Sans Pro";
      font-size: 28px; }
    .step__container .step h3 {
      margin-bottom: 2%;
      font-family: "Source Sans Pro";
      font-weight: 400;
      line-height: 22px;
      font-size: 16px; }
      .step__container .step h3 strong {
        font-size: 18px;
        line-height: 25px; }
    .step__container .step li {
      font-family: "Source Sans Pro";
      list-style: none;
      margin-bottom: 2%;
      font-size: 18px; }
    .step__container .step button {
      padding: 10px 30px;
      min-width: 140px;
      background: transparent;
      text-transform: uppercase;
      font-size: 13px;
      transition: all 0.4s;
      margin-top: 5%;
      border: 1px solid #292929;
      float: right;
      letter-spacing: 1px; }
      .step__container .step button:hover {
        cursor: pointer;
        background: #292929;
        color: white;
        transition: all 0.4s; }

#nprogress .bar {
  background: #1f5266;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: absolute; }

#nprogress .peg {
  display: none; }

#nprogress .spinner-icon {
  display: none; }

.success__container {
  display: flex;
  justify-content: space-around;
  padding: 10% 10% 10% 0%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  @media (max-width: 768px) {
    .success__container {
      flex-direction: column;
      justify-content: center;
      width: 90%;
      position: relative; } }
  .success__container h1 {
    font-family: "paris2024semibold";
    text-transform: uppercase; }
  .success__container a {
    text-transform: none;
    color: inherit;
    border-bottom: 1px solid #292929; }
  @media (max-width: 768px) {
    .success__container button {
      clear: both;
      width: 100%; } }
  .success__container .icon {
    font-size: 60px;
    margin-right: 20px; }
    .success__container .icon.bounce-top {
      -webkit-animation: bounce-top 0.9s both;
      animation: bounce-top 0.9s both; }
  .success__container .loader {
    background-color: #292929;
    width: 20%;
    height: 100vh;
    z-index: 1000000000;
    position: absolute;
    transition: all 0.4s; }

@-webkit-keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in; }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in; }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in; }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out; }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1; } }

@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1; }
  24% {
    opacity: 1; }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in; }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in; }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in; }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in; }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0px);
    animation-timing-function: ease-out; }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1; } }

.page__container .header__container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  position: fixed;
  width: 100%;
  top: 0px;
  color: white;
  z-index: 20;
  font-size: 19px;
  box-sizing: border-box; }
  .page__container .header__container div {
    width: calc(100% / 2); }
    @media (max-width: 960px) {
      .page__container .header__container div {
        width: 100%; } }
    @media (max-width: 960px) {
      .page__container .header__container div:nth-child(1) {
        margin-left: 10px; } }
    .page__container .header__container div:nth-child(2) {
      text-align: center;
      display: none; }
      @media (max-width: 960px) {
        .page__container .header__container div:nth-child(2) {
          text-align: right;
          margin-right: 10px; } }
    .page__container .header__container div:nth-child(3) {
      text-align: right; }
  .page__container .header__container .logo_jo {
    width: 40%;
    max-width: 150px; }
    @media (max-width: 960px) {
      .page__container .header__container .logo_jo {
        width: 35%; } }
  .page__container .header__container .logo_tdj {
    width: 20%;
    max-width: 90px; }
  @media (max-width: 960px) {
    .page__container .header__container {
      position: fixed;
      align-items: center;
      width: 100%; } }
  .page__container .header__container button {
    background-color: #292929;
    color: white;
    border: 0;
    float: none;
    transition: all 0.4s;
    margin-top: 0;
    white-space: nowrap; }
    .page__container .header__container button:hover {
      background-color: #1f5266;
      color: white;
      transition: all 0.4s; }
    @media (max-width: 960px) {
      .page__container .header__container button {
        width: 100%;
        position: fixed;
        padding: 20px;
        bottom: -5px;
        left: 0;
        z-index: 40; } }
  .page__container .header__container.fixed {
    background-color: white;
    padding: 1%;
    align-items: center;
    transition: all 0.4s;
    z-index: 30; }
    .page__container .header__container.fixed div:nth-child(2) {
      display: block; }
    @media (max-width: 960px) {
      .page__container .header__container.fixed .logo_tdj {
        width: 25%; }
      .page__container .header__container.fixed .logo_jo {
        width: 50%; } }
    .page__container .header__container.fixed button {
      margin-top: 0px;
      margin-right: 20px; }
      @media (max-width: 1472px) {
        .page__container .header__container.fixed button {
          margin-top: 10px;
          min-width: 276px; } }

.page__container .embed__container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden; }
  .page__container .embed__container iframe,
  .page__container .embed__container object,
  .page__container .embed__container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.page__container .video__container .video__titles {
  z-index: 20;
  color: white;
  padding: 10%;
  position: absolute;
  top: 30%;
  pointer-events: none;
  transition: opacity 0.4s; }
  @media (max-width: 960px) {
    .page__container .video__container .video__titles {
      z-index: 1;
      position: relative;
      top: 0;
      transform: none;
      padding: 5%;
      color: #292929;
      display: flex;
      flex-direction: column-reverse; } }
  .page__container .video__container .video__titles .bar {
    display: none; }
    @media (max-width: 960px) {
      .page__container .video__container .video__titles .bar {
        margin-top: 30px;
        display: block;
        width: 100%;
        border-bottom: 1px solid #c1c1c1; } }
  .page__container .video__container .video__titles.hidden {
    opacity: 0;
    transition: opacity 0.4s; }
    @media (max-width: 960px) {
      .page__container .video__container .video__titles.hidden {
        opacity: 1; } }
  .page__container .video__container .video__titles h1 {
    font-family: "paris2024bold";
    text-transform: uppercase;
    font-size: 50px;
    width: 45%;
    font-weight: 900;
    opacity: 1;
    transition: opacity 0.4s; }
    @media (max-width: 1280px) {
      .page__container .video__container .video__titles h1 {
        font-size: 30px;
        margin-bottom: 10px;
        width: 60%; } }
    @media (max-width: 960px) {
      .page__container .video__container .video__titles h1 {
        font-size: 27px;
        margin-bottom: 10px;
        width: 90%; } }
  .page__container .video__container .video__titles h4 {
    font-size: 20px;
    opacity: 1;
    transition: opacity 0.4s; }
    @media (max-width: 960px) {
      .page__container .video__container .video__titles h4 {
        font-size: 15px;
        margin-bottom: 10px; } }
  .page__container .video__container .video__titles button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    float: none;
    pointer-events: initial; }
    .page__container .video__container .video__titles button:hover {
      background-color: white;
      color: #292929;
      transition: all 0.4s; }
    @media (max-width: 1280px) {
      .page__container .video__container .video__titles button {
        display: none; } }
    @media (max-width: 960px) {
      .page__container .video__container .video__titles button {
        color: #292929;
        border: 1px solid #292929;
        margin: 0 auto 30px auto; } }

.page__container .home__content {
  display: flex; }
  @media (max-width: 960px) {
    .page__container .home__content {
      flex-direction: column; } }
  .page__container .home__content .white__part {
    width: 55%;
    padding: 40px;
    padding: 2% 4%; }
    .page__container .home__content .white__part h3 {
      font-family: "paris2024semibold"; }
    @media (max-width: 960px) {
      .page__container .home__content .white__part {
        width: 90%;
        margin: 0 auto; } }
    .page__container .home__content .white__part div {
      display: flex; }
      @media (max-width: 1300px) {
        .page__container .home__content .white__part div {
          flex-direction: column; } }
    .page__container .home__content .white__part p {
      font-size: 15px; }
    .page__container .home__content .white__part button {
      background-color: #292929;
      color: white;
      border: 0;
      float: none;
      font-size: 15px;
      padding: 10px 30px; }
      .page__container .home__content .white__part button:hover {
        background-color: #1f5266;
        color: white;
        transition: all 0.4s; }
      @media (max-width: 960px) {
        .page__container .home__content .white__part button.hideOnMobile {
          visibility: hidden; } }
  .page__container .home__content .black__part {
    background-color: #292929;
    top: -55px;
    position: relative;
    padding: 20px 50px 30px 50px;
    z-index: 20; }
    @media (max-width: 960px) {
      .page__container .home__content .black__part {
        top: -15px;
        z-index: 1;
        padding: 5%; } }
    .page__container .home__content .black__part__list > div {
      display: flex;
      align-items: center; }
      .page__container .home__content .black__part__list > div p {
        margin-left: 15px; }
    .page__container .home__content .black__part h3 {
      color: white;
      font-family: "paris2024semibold"; }
      @media (max-width: 960px) {
        .page__container .home__content .black__part h3 {
          margin: 5% 0; } }
    .page__container .home__content .black__part p {
      color: white;
      font-size: 15px; }
  .page__container .home__content h3 {
    font-size: 30px;
    font-weight: 900; }

.page__container .logo__container {
  width: 80%;
  margin: 80px auto 0 auto; }
  @media (max-width: 960px) {
    .page__container .logo__container {
      margin: 0 auto; } }
  .page__container .logo__container .logo__title {
    font-family: "paris2024semibold";
    display: flex;
    align-items: center;
    padding: 100px 0; }
    @media (max-width: 960px) {
      .page__container .logo__container .logo__title {
        padding: 50px 0; } }
    .page__container .logo__container .logo__title .bar {
      width: 100%;
      border-bottom: 1px solid #c1c1c1; }
      @media (max-width: 960px) {
        .page__container .logo__container .logo__title .bar {
          display: none; } }
    .page__container .logo__container .logo__title span {
      width: 70%;
      text-align: center;
      font-size: 2rem; }
      @media (max-width: 960px) {
        .page__container .logo__container .logo__title span {
          width: 100%; } }
  .page__container .logo__container .logos div {
    padding: 100px 0;
    display: flex;
    justify-content: space-between; }
    .page__container .logo__container .logos div img {
      width: 11rem;
      margin: 2px 0px; }
      @media (max-width: 768px) {
        .page__container .logo__container .logos div img {
          width: 9rem; } }
    @media (max-width: 768px) {
      .page__container .logo__container .logos div {
        flex-direction: column;
        padding: 20px 0; } }
  .page__container .logo__container .logos .logos__firstLine {
    padding: 4px 0;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .page__container .logo__container .logos .logos__firstLine {
        padding: 0 !important;
        width: 100%;
        flex-direction: row;
        padding: 20px 0;
        flex-wrap: wrap;
        justify-content: space-around; } }
  .page__container .logo__container .premium {
    display: flex;
    justify-content: center; }
    .page__container .logo__container .premium img {
      width: 11rem; }
      @media (max-width: 768px) {
        .page__container .logo__container .premium img {
          width: 9rem; } }
    .page__container .logo__container .premium .edf {
      width: 10%;
      margin-left: 10px; }
    @media (max-width: 768px) {
      .page__container .logo__container .premium {
        padding: 0; }
        .page__container .logo__container .premium img {
          margin: 0 auto;
          margin-bottom: 50px; } }
  .page__container .logo__container .officials--partners {
    display: flex;
    justify-content: center;
    padding-bottom: 150px; }
    .page__container .logo__container .officials--partners img {
      width: 11rem; }
    @media (max-width: 768px) {
      .page__container .logo__container .officials--partners {
        padding: 0; }
        .page__container .logo__container .officials--partners img {
          margin: 0 auto;
          margin-bottom: 50px;
          width: 9rem; } }
  .page__container .logo__container .officials--supporters {
    display: flex;
    justify-content: center;
    padding-bottom: 150px; }
    .page__container .logo__container .officials--supporters img {
      width: 12%; }
    @media (max-width: 768px) {
      .page__container .logo__container .officials--supporters {
        padding: 0; }
        .page__container .logo__container .officials--supporters img {
          margin: 0 auto;
          margin-bottom: 50px; } }

.page__container .footer__container {
  background-color: #292929;
  padding: 100px 100px 50px 100px;
  text-align: center; }
  .page__container .footer__container .title__and__logo {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center; }
  .page__container .footer__container .logo__footer {
    width: 150px;
    margin-bottom: 100px; }
  @media (max-width: 960px) {
    .page__container .footer__container {
      padding: 60px 30px 30px 30px; }
      .page__container .footer__container .logo__footer {
        display: none; } }
  .page__container .footer__container span {
    font-size: 25px;
    text-transform: uppercase;
    color: white;
    font-weight: 500; }
    @media (max-width: 960px) {
      .page__container .footer__container span {
        font-size: 18px; } }
  .page__container .footer__container h3 {
    color: white;
    font-family: "paris2024bold";
    font-size: 40px;
    font-weight: 900;
    margin: 45px 0 100px 0; }
    @media (max-width: 960px) {
      .page__container .footer__container h3 {
        font-size: 25px;
        margin: 20px 0;
        text-align: left; } }
  .page__container .footer__container .footer__links {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px; }
    @media (max-width: 960px) {
      .page__container .footer__container .footer__links {
        justify-content: space-between;
        padding-bottom: 50px;
        align-items: flex-start;
        flex-direction: column; } }
    .page__container .footer__container .footer__links a {
      color: white;
      border-bottom: 1px solid white;
      font-size: 15px;
      display: flex;
      align-items: flex-end;
      margin: 0 80px; }
      .page__container .footer__container .footer__links a:nth-last-child() {
        margin-right: 0; }
      @media (max-width: 960px) {
        .page__container .footer__container .footer__links a {
          margin: 0 0 20px 0; } }
  .page__container .footer__container > div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto; }
    @media (max-width: 960px) {
      .page__container .footer__container > div {
        justify-content: space-between;
        padding-bottom: 50px;
        align-items: flex-start;
        flex-direction: column; } }

.exlore__container {
  text-align: left;
  height: 100vh;
  display: flex;
  overflow: hidden; }
  @media (max-width: 1130px) {
    .exlore__container {
      flex-direction: column;
      padding: 15% 5%; } }
  .exlore__container h1 {
    font-family: paris2024bold;
    color: #1f5266;
    font-size: 50px;
    font-weight: 900;
    flex-wrap: wrap;
    display: flex; }
    .exlore__container h1 span {
      color: #ff5d71; }
    @media (max-width: 960px) {
      .exlore__container h1 {
        font-size: 23px;
        margin-top: 40px; } }
  .exlore__container h2 {
    font-size: 25px;
    font-weight: 900;
    font-family: paris2024semibold;
    margin: 70px 0 30px 0; }
    @media (max-width: 960px) {
      .exlore__container h2 {
        margin: 20px 0 35px 0;
        width: 100%;
        font-size: 15px;
        line-height: 20px; } }
  .exlore__container a {
    margin-bottom: 70px; }
  .exlore__container .left__part {
    padding-top: 10%;
    padding-left: 12%;
    padding-right: 5%;
    width: 50%;
    box-sizing: border-box;
    background-color: whitesmoke;
    overflow: auto; }
    @media (max-width: 1130px) {
      .exlore__container .left__part {
        width: 100%;
        background-color: white;
        padding: 0;
        overflow: initial; } }
    .exlore__container .left__part a {
      margin-top: 30px; }
      @media (max-width: 1130px) {
        .exlore__container .left__part a {
          margin: 40px 0; } }
  .exlore__container .right__part {
    width: 50%;
    padding: 12% 0;
    text-align: center;
    margin: 0 auto; }
    @media (max-width: 1130px) {
      .exlore__container .right__part {
        width: 100%;
        padding: 0;
        text-align: left; } }
    .exlore__container .right__part h2 {
      margin-top: 0; }

.mapSlider__container {
  margin: 0 auto;
  width: 60%; }
  @media (max-width: 1130px) {
    .mapSlider__container {
      width: 100%; } }
